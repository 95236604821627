export default [
   
    {
      title: 'ห้องตรวจ',
      customicon: 'room',
    
      tagVariant: 'light-warning',
      action: 'read',
      children: [
        {
          title: 'ห้องตรวจ',
          route: 'room-read',  
      
          resource: 'room',
          action: 'read',
        },
      ]
     
    },
    
   
  ]