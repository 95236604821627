//stock
export default [
   
    {
      title: 'Receipt',
      //customicon: 'receipt-lines',
      icon:'FileTextIcon',
      tagVariant: 'light-warning',
      action: 'read',
      children: [
        {
          title: 'Receipt List',
          route: 'receipt-read',  
      
          resource: 'receipt',
          action: 'read',
        },
        {
          title: 'Status',
          route: 'receipt-status-read',  
      
          resource: 'receipt',
          action: 'receipt_status_read',
        },
      ]
     
    },
    
   
  ]