export default [
    
    {
        title: 'patients',
        icon: 'UserIcon',
       
        tagVariant: 'light-warning',
        resource: 'Patients',
        action: 'read',
        children: [
          {
              title: 'patients:information',
              route: 'patients-read',  
          
              resource: 'Patients',
              action: 'read',
          },
          {
            title: 'patients:create',
            route: 'patients-create',  
        
            resource: 'Patients',
            action: 'create',
          },
          {
            title: 'เชื่อมต่อ Line Notify',
            route: 'linenotify-read',  
            
            resource: 'Linenotify',
            action: 'read',
          },
          {
              title: 'กลุ่มลูกค้า',
              route: 'patients-group-read',  
              
              resource: 'Patients',
              action: 'read',
          },
          {
            title: 'แท็กลูกค้า',
            route: 'patients-tag-read',  
            
            resource: 'Patients',
            action: 'read',
          },
          {
            title: 'ค้นหาประวัติ OPD',
            route: 'opd-read',  
            
            resource: 'Patients',
            action: 'read',
        },
  
        ]
       
       
      },
]