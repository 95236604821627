export default [
    
    {
        title: 'จัดการบัตรกำนัล',
        customicon: 'voucher',
       
        tagVariant: 'light-warning',
        resource: 'voucher',
        action: 'read',
        children: [
          {
              title: 'บัตรกำนัล',
              route: 'voucher-read',  
          
              resource: 'voucher',
              action: 'read',
          },
          {
            title: 'หมวดหมู่-ประเภท บัตรกำนัล',
            route: 'voucher-type-read',  
        
            resource: 'voucher',
            action: 'category_read',
        },
          
        
  
        ]
       
       
      },
]