//stock
export default [
   
    {
      title: 'จัดการคอร์ส',
      customicon: 'course_mgn',
    
      tagVariant: 'light-warning',
      action: 'read',
      children: [
        {
          title: 'ซื้อคอร์ส',
          route: 'courseset-read', 

          resource: 'courseset',
          action: 'read',
        },
        {
          title: 'รายครั้ง',
          route: 'course-read',  
      
          resource: 'course',
          action: 'read',
        },
        {
          title: 'หมวดหมู่-ประเภท คอร์ส',
          route: 'course_category-read',  
      
          resource: 'course',
          action: 'category_read',
        },
      ]
     
    },
    
   
  ]