export default [
    
    {
        title: 'จัดการคิว',
        customicon: 'queue',
       
        tagVariant: 'light-warning',
       
        children: [
          {
              title: 'ห้องตรวจ',
              route: 'queue-read',  
              resource: 'Queue',
              action: 'queue_examination',
              
          },
          
          {
            title: 'ห้องใช้บริการ',
            route: 'queue-service',  
        
            resource: 'Queue',
            action: 'queue_services',
          },
          /*{
            title: 'ชำระเงิน',
            route: 'queue-pament',  
        
            resource: 'Queue',
            action: 'queue_payment',
          },*/
  
        ]
       
       
      },
]