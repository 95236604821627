//stock
export default [
   
  {
    title: 'จัดการยา/อุปกรณ์',
    customicon: 'medicine',
  
    tagVariant: 'light-warning',
    action: 'read',
    children: [
      {
          title: 'ยา/อุปกรณ์',
          route: 'medicine-read',  
      
          resource: 'medicine',
          action: 'read',
      },
      {
        title: 'กลุ่มยา/อุปกรณ์',
        route: 'medicine_group-read',  
    
        resource: 'medicine',
        action: 'group_read',
      },
      {
        title: 'หมวดหมู่-ประเภทยา/อุปกรณ์',
        route: 'medicine_category-read',  
    
        resource: 'medicine',
        action: 'category_read',
      },
      {
        title: 'หน่วยวัด',
        route: 'unit-read',  
    
        resource: 'unit',
        action: 'read',
      },
      {
        title: 'บริษัทผู้ผลิต',
        route: 'warehouse-read',  
    
        resource: 'warehouse',
        action: 'read',
      },
     /* {
        title: 'สร้างรายการปรับปรุงสต๊อก',
        route: 'stock-read',  
    
        resource: 'stock',
        action: 'read',
      },*/
      {
        title: 'รอบสต๊อก',
        route: 'stock-cycle-read',  
    
        resource: 'stock',
        action: 'read',
      },
    ]
   
  },
  
 
]