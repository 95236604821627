export default [
    {
        title: 'พนักงาน',
        resource: 'User',
        customicon: 'users-profiles-01',
        action: 'read',
        tagVariant: 'light-warning',
        children: [
          {
            title: 'พนักงาน',
           
            route: 'user-read',  
            tagVariant: 'light-warning',
            resource: 'User',
            action: 'read',
           
          },
          
          {
              title: 'กลุ่ม',
             
              route: 'group-read',  
              tagVariant: 'light-warning',
              resource: 'Group',
              action: 'read',
             
            },

          {
            title: 'สาขา',
           
            route: 'branch-read',  
            tagVariant: 'light-warning',
            resource: 'Branch',
            action: 'read',
           
          },
        ]
       
        
    },
    
  ]