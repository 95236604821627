export default [
    
    {
        title: 'รายการตรวจ',
        customicon: 'checklist',
       
        tagVariant: 'light-warning',
        resource: 'Checklists',
        action: 'read',
        children: [
          {
              title: 'รายการตรวจ',
              route: 'checklists-read',  
          
              resource: 'Checklists',
              action: 'read',
          },
          {
            title: 'ชุดการตรวจ',
            route: 'checklists-set-read',  
        
            resource: 'Checklists',
            action: 'read',
        },
        {
          title: 'หมวดหมู่การตรวจ',
          route: 'checklists-category-read',  
          
          resource: 'Checklists',
          action: 'advice_read',
      },
          {
              title: 'คำแนะนำการตรวจแล็บ',
              route: 'checklists-advice-read',  
              
              resource: 'Checklists',
              action: 'advice_read',
          },
        
  
        ]
       
       
      },
]