<template>
  <svg version="1.1" :class="[clazz,'icon-' + name]" :width="icon.width" :height="icon.height" :viewBox="box">
    <path :d="path.d" v-for="path in icon.paths" />
  
  </svg>
   
   <!--<icon name="medicine" class="icon-custom" :w="10" :h="10" fill="#fff" stoke="#fff" ></icon>-->


</template>
<script>
import { ref,onUnmounted } from '@vue/composition-api'
//import '@/assets/images/icons'
const convert = require('./lib/parse');
export default {
    name: 'CustomIcon',
    props: {
    name: {
        required: false,
        type: [String, Object],
        },
    
    },
    computed: {
      clazz() {
        return {
          'svg-icon': true
        }
      },
      icon() {
        let xml =  require(`!xml-loader!@/assets/svg/${this.name}.svg`);
        
        const t = xml.svg.$.viewBox.split(' ');
        let paths = convert.SVGtoArray(xml.svg);
      //  paths[0].fill = '#ffffff';
        //paths[0].stroke = '#ffffff';
        //console.log('paths',paths);
       // const t = xml.svg.viewBox.split(' ');
        // console.info(`src/svg/${this.name}.svg has been loaded`);
        return {
          width: t[2],
          height: t[3],
          paths: paths
        }
      },
      box() {
        return `0 0 ${this.icon.width} ${this.icon.height}`
      }
    },
    


}
</script>

<style scoped>
  .svg-icon{
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
  }
</style>