/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
import users from './users'
import settings from './settings'
import content from './content'
import patients from './patients'
import course  from './course'
import medicine from './medicine'
import meterial from './meterial'
import checklists from './checklists'
import voucher from './voucher'
import appointment from './appointment'
import room from './room'
import queue from './queue'
import receipt from './receipt'
import report from './report'

// Array of sections
export default [...dashboard,...patients,...queue,...appointment, ...course,...medicine,...meterial, ...receipt, ...report,...checklists,...room,...voucher,...users,...settings]
