//stock
export default [
   
    {
      title: 'จัดการนัดหมาย',
      customicon: 'calender',
    
      tagVariant: 'light-warning',
      action: 'read',
      children: [
       
        {
          title: 'Calendar',
          route: 'appointment-calendar',  
      
          resource: 'appointment',
          action: 'appointment_calendar',
        },
        {
          title: 'นัดหมาย',
          route: 'appointment-read',  
      
          resource: 'appointment',
          action: 'read',
        },
      ]
     
    },
    
   
  ]