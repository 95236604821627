export default [
    {
        header: 'Settings',
        resource: 'Settings',
        action: 'permission',
     
    },
   
    {
      title: 'Permission',
      customicon: 'key-01',
      route: 'settings-groups',  
      tagVariant: 'light-warning',
      resource: 'Settings',
      action: 'permission',
     
     
    },
]