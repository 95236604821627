//stock
export default [
   
    {
      title: 'Report',
      //customicon: 'receipt-lines',
      icon:'FileTextIcon',
      tagVariant: 'light-warning',
      action: 'read',
      children: [
        {
          title: 'รายรับประจำวัน(รายละเอียด)',
          route: 'report-sales-daily',  
      
          resource: 'report',
          action: 'read',
        },
        {
          title: 'รายรับ(สรุป)',
          route: 'report-sales',  
      
          resource: 'report',
          action: 'read',
        },
        {
          title: 'รายรับประจำวันแบ่งตามประเภทสินค้า',
          route: 'report-sales-types',  
      
          resource: 'report',
          action: 'read',
        },
        {
          title: 'รายรับประจำวันแบ่งตามประเภทสินค้า(รายละเอียด)',
          route: 'report-sales-types-detail',  
      
          resource: 'report',
          action: 'read',
        },
        {
          title: 'รายงานการจ่ายสินค้าตามชื่อลูกค้า',
          route: 'report-user-product',  
      
          resource: 'report',
          action: 'read',
        },
        {
          title: 'รายงานการจ่ายสินค้าตามวันที่',
          route: 'report-date-products',  
      
          resource: 'report',
          action: 'read',
        },
        {
          title: 'รายงานการจ่ายสินค้าตามชื่อ',
          route: 'report-name-products',  
      
          resource: 'report',
          action: 'read',
        },
        {
          title: 'รายชื่อลูกค้าใหม่',
          route: 'report-patients-new',  
      
          resource: 'report',
          action: 'read',
        },
        {
          title: 'รายชื่อลูกค้าประจำวัน',
          route: 'report-patients-list',  
      
          resource: 'report',
          action: 'read',
        },
        {
          title: 'รายชื่อลูกค้าประจำวันเรียงตามใบเสร็จ',
          route: 'report-patients-receipt',  
      
          resource: 'report',
          action: 'read',
        },
        {
          title: 'รายชื่อลูกค้าประจำวันเรียงตามยอดการชำระเงิน',
          route: 'report-patients-receipt-sales',  
      
          resource: 'report',
          action: 'read',
        },
        {
          title: 'รายงานยอดข้อมูลหัตถการ',
          route: 'report-services',  
      
          resource: 'report',
          action: 'read',
        },
        {
          title: 'Stock',
          route: 'report-stocks',  
      
          resource: 'report',
          action: 'read',
        },
        {
          title: 'ค่ามือแพทย์',
          route: 'report-fee-medical',  
      
          resource: 'report',
          action: 'manager',
        },
        {
          title: 'ค่ามือผู้ช่วย',
          route: 'report-fee-assistant',  
      
          resource: 'report',
          action: 'manager',
        },
       
      ]
     
    },
    
   
  ]