export default [
  {
    title: 'Dashboards',
    //icon: 'HomeIcon',
    customicon: 'dashboard',
    route: 'dashboard-coparate',
    tagVariant: 'light-warning',
    resource: 'Dash',
    action: 'read',
   
  },
]
