//stock
export default [
   
    {
      title: 'จัดการวัสดุ/บริการ',
      customicon: 'list_service',
    
      tagVariant: 'light-warning',
      action: 'read',
      children: [
        {
          title: 'วัสดุ/บริการ',
          route: 'meterial-read',  
      
          resource: 'meterial',
          action: 'read',
        },
        {
          title: 'หมวดหมู่-ประเภทวัสดุ/บริการ',
          route: 'meterial_category-read',  
      
          resource: 'meterial',
          action: 'read',
        },
      ]
     
    },
    
   
  ]